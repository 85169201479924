<template>
  <div>
    <dashboard-navbar :routes="routes"></dashboard-navbar>
    <div class="content" @click="$sidebar.displaySidebar(false)">
      <div class="container-fluid mt-2">
        <card body-classes="px-0 pb-1" footer-classes="pb-2">
          <template slot="header">
            <h2 class="mb-0">{{ getInfoSelectedStudent.name }} Courses</h2>
          </template>
          <div>
            <div class="col-12 d-flex justify-content-center justify-content-sm-between mb-3">
              <div class="wrapper-select">
                <span>Show</span>
                <div class="select-row">
                  <el-select
                    class="select-primary pagination-select"
                    size="mini"
                    v-model="pagination.perPage"
                    placeholder="Per page"
                  >
                    <el-option
                      class="select-primary"
                      size="mini"
                      v-for="item in pagination.perPageOptions"
                      :key="item"
                      :label="item"
                      :value="item"
                    ></el-option>
                  </el-select>
                </div>
                <span>Row</span>
              </div>
              <div class="form-search">
                <base-input v-model="searchQuery" placeholder="Search Records" size="mini"></base-input>
              </div>
            </div>
            <el-table
              :data="queriedData"
              row-key="id"
              header-row-class-name="thead-light"
              @sort-change="sortChange"
              @selection-change="selectionChange"
            >
              <el-table-column
                :filters="[{ text: 'Favorite', value: true }, { text: 'Not-Favorite', value: false }]"
                :filter-method="filterFavorite"
                filter-placement="bottom-end"
                prop="favorite"
                label="Favorite"
                width="130"
              >
                <template slot-scope="scope">
                  <el-tag disable-transitions>
                    <img
                      v-if="scope.row.favorite"
                      src="/img/icons/favorite.svg"
                      class="text-center"
                      alt="favorite"
                    />
                    <img v-else src="/img/icons/no-favorite.png" alt="not-favorite" />
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column v-for="column in tableColumns" :key="column.label" v-bind="column"></el-table-column>
              <el-table-column prop="status" label="Status" width="120">
                <template slot-scope="scope">
                  <el-tag
                    disable-transitions
                    :class="statusClass(scope.row.status)"
                  >{{ scope.row.status }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="purchase" label="Purchase Date" width="200" sortable></el-table-column>
            </el-table>
          </div>
          <div
            slot="footer"
            class="col-12 d-flex justify-content-center justify-content-sm-between flex-wrap"
          >
            <div class>
              <p class="card-category">
                Showing {{ from + 1 }} to {{ to }} of {{ total }} entries
                <span
                  v-if="selectedRows.length"
                >&nbsp; &nbsp; {{ selectedRows.length }} rows selected</span>
              </p>
            </div>
            <base-pagination
              class="pagination-no-border"
              v-model="pagination.currentPage"
              :per-page="pagination.perPage"
              :total="total"
            ></base-pagination>
          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { Table, TableColumn, Tag, Select, Option } from 'element-ui'
import { BasePagination } from '@/components';
import DashboardNavbar from '@/Layout/DashboardNavbar.vue';
import listCourseMixin from './Mixins/listCourseMixin'
import { getMyCourse } from '@/services/student'
import { mapGetters } from 'vuex'
import Fuse from "fuse.js";
export default {
  mixins: [listCourseMixin],
  name: "StudentCourse",
  components: {
    BasePagination,
    DashboardNavbar,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Tag.name]: Tag
  },
  data() {
    return {
      propsToSearch: ['name', 'category.name', 'progress', 'point', "examDate", "purchase", "status"],
      tableColumns: [
        {
          prop: 'name',
          label: 'Course Title',
          minWidth: 250,
          sortable: true
        },
        {
          prop: 'category.name',
          label: 'Category-course',
          minWidth: 250,
          sortable: true
        },
        {
          prop: 'progress',
          label: 'Progress',
          minWidth: 150,
          sortable: true
        },
        {
          prop: 'point',
          label: 'Exam Point',
          minWidth: 150,
          sortable: true
        },
        {
          prop: 'examDate',
          label: 'Exam Date',
          minWidth: 150,
          sortable: true
        },
      ],
      tableData: [],
      selectedRows: [],
      routes: [
        {
          name: "ListStudents",
          alias: "LIST STUDENTS"
        },
        {
          name: "ListStudentsCourse",
          alias: "STUDENT COURSES"
        },
      ]
    };
  },
  methods: {
    statusClass(status) {
      return status === "Learn" ? "learn" : status === "Pass" ? "pass" : "failed"
    },
    handleDetail(index, row) {
      this.$router.push({ name: 'ListStudentsId', params: { id: row.id } })
    },
    handleCourse(index, row) {
      this.$router.push({ name: 'ListStudentsCourse', params: { id: row.id } })
    },
    filterFavorite(value, row) {
      return row.favorite == value;
    },
    selectionChange(selectedRows) {
      this.selectedRows = selectedRows
    },
    async getCourse() {
      try {
        const { data } = await getMyCourse(this.$route.params.id)
        this.tableData = data
        this.fuseSearch = new Fuse(this.tableData, {
          keys: this.propsToSearch,
          threshold: 0.3
        });
      } catch (error) {
        this.$notify({
          type: "danger",
          verticalAlign: "top",
          horizontalAlign: "center",
          closeOnClick: true,
          message: error
        });
      }
    }
  },
  computed: {
    ...mapGetters("student", ["getInfoSelectedStudent"]),
  },
  mounted() {
    this.getCourse()
  }
}
</script>
<style lang="scss" scoped>
@import "@/assets/sass/argon.scss";

.pagination-select {
  margin: 0 5px;
}
.wrapper-select {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  .select-row {
    width: 75px;
    margin-right: 10px;
  }
}

.el-tag {
  background-color: #fff !important;
  border-color: #fff !important;
  &.pass {
    color: $status-pass-ar-vr;
  }
  &.learn {
    color: $status-learn-ar-vr;
  }
  &.failed {
    color: $status-failed-ar-vr;
  }
}
</style>
    